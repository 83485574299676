/* You can add global styles to this file, and also import other style files */

@import '~bootstrap/dist/css/bootstrap.min.css';

.avatar-22 {
  border-radius: 14px 0 0 14px;
  width: 22px;
  height: 22px;
}

.avatar-assignee-22 {
  border-radius: 0 14px 14px 0;
  width: 22px;
  height: 22px;
}

.avatar-32 {
  border-radius: 3px;
  width: 32px;
  height: 32px;
}

.avatar-40 {
  border-radius: 3px;
  width: 40px;
  height: 40px;
}

.avatar-48 {
  width: 48px;
  height: 48px;
  border-radius: 5px;
}

@font-face {
  font-family: "Noto Color Emoji";
  src: url("assets/NotoColorEmoji.ttf");
}

.gravity-left {
  right: 0;
}

.gravity-right {
  left: 0;
}

.gravity-top {
  bottom: 0;
}

.gravity-bottom {
  top: 0;
}
